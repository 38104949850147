module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"dougmcdonald.co.uk","short_name":"dougmcdonald.co.uk","start_url":"/","background_color":"#ffffff","theme_color":"#2DCACA","display":"standalone","icon":"src/images/favicon.png","crossOrigin":"use-credentials"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590,"wrapperStyle":"margin-left: 0!important; margin-right: 0!important;"},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
